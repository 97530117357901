<template>
    <el-container>
        <el-main>
            <el-form ref="form" :model="form" label-width="200px" :rules="rules">
                <el-form-item label="餐位费金额：" prop="seat_in_the_restaurant_cost">
                    <el-input v-model="form.seat_in_the_restaurant_cost" class="nomalwidth">
                        <template slot="append">元/位</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="订单整体打包金额：" prop="pack_cost">
                    <el-input v-model="form.pack_cost" class="nomalwidth">
                        <template slot="append">元</template>
                    </el-input>
                    <span class="tips">订单整体收取的打包金额</span>
                </el-form-item>
                <el-form-item label="订单超过：" prop="order_timeout_close">
                    <el-input v-model="form.order_timeout_close" class="nomalwidth" type="number">
                        <template slot="append">分</template>
                    </el-input>
                    <span class="tips">未付款，订单自动关闭</span>
                </el-form-item>
                <el-form-item label="出餐规则：" prop="meal_setting">
                    <el-select v-model="form.meal_setting" placeholder="请选择" class="nomalwidth">
                        <el-option label="先付款后出餐" :value="0">
                        </el-option>
                        <el-option label="先出餐后付款" :value="1">
                        </el-option>
                    </el-select>
                    <span class="tips">默认为先付款后出餐</span>
                </el-form-item>
            </el-form>
        </el-main>
        <Preservation @preservation="saveForm"></Preservation>
    </el-container>
</template>

<script>
import { inspectPrice } from '@/util/verification';
import Preservation from '@/components/preservation';
export default {
    components: {
        Preservation,
    },
    data: function () {
        return {
            form: {
                seat_in_the_restaurant_cost: '',
                pack_cost: '',
                meal_setting: 0,
                order_timeout_close: 10
            },
            rules: {
                seat_in_the_restaurant_cost: [{ required: true, message: "请填写餐位费金额", trigger: "blur" }, {
                    validator: (rule, value, callback) => {
                        if (!inspectPrice(value)) {
                            callback(new Error("请填写正确价格格式"));
                        } else {
                            callback();
                        }
                    }, trigger: "blur"
                }],
                pack_cost: [{ required: true, message: "请填写单整体打包金额", trigger: "blur" }, {
                    validator: (rule, value, callback) => {
                        if (!inspectPrice(value)) {
                            callback(new Error("请填写正确价格格式"));
                        } else {
                            callback();
                        }
                    }, trigger: "blur"
                }]
            },
        };
    },
    created () {
        this.getInfo()
    },
    methods: {
        getInfo () {
            this.$axios.post(this.$api.catering.OrderSetInfo).then((res) => {
                if (res.code == 0) {
                    let obj = res.result
                    if (obj) {
                        this.form = {
                            seat_in_the_restaurant_cost: obj.seat_in_the_restaurant_cost,
                            pack_cost: obj.pack_cost,
                            meal_setting: obj.meal_setting,
                            order_timeout_close: obj.order_timeout_close
                        };
                    }
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        saveForm () {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.$axios.post(this.$api.catering.OrderSetEdit, this.form).then((res) => {
                        if (res.code == 0) {
                            this.$message.success('保存成功');
                            this.getInfo()
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.el-main {
    height: 100%;
    background: #fff;
    padding-bottom: 50px;
}


.nomalwidth {
    width: 500px;
}

.tips {
    color: #999;
    margin-left: 10px;
}
</style>
