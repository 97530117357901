// 验证手机号
export function inspectPhone(val) {
  let myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
  return myreg.test(val);
}
// 验证非负浮点数
export function inspectFloat(val) {
  let myreg = /^\d+(\.\d+)?$/;
  return myreg.test(val);
}

// 验证价格(非负浮点数,最多两位小数)
export function inspectPrice(val) {
  let myreg = /^(0|([1-9]\d*))(\.\d{1,2})?$/;
  return myreg.test(val);
}
